import React from 'react';

class Page extends React.Component {
  componentDidMount() { 
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });        
  }

  render() {
    return this.props.children;
  }
}
export default Page;