import React from 'react';
import "./Paper.css";

const Paper = (props) => {
  return ( 
    <div className="paper" style={props.style}>
      {props.children}
    </div>
   );
}
 
export default Paper;