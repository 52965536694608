import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import AudioCatalogue from './AudioCatalogue';

class Audio extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Assets | Hentamine</title>
          <link rel="canonical" href="https://hentamine.com/audio" />
          <meta name="description" content="Discover and listen to samples of our audio assets made for video game designers and developers." />
        </Helmet>
        <AudioCatalogue />
      </React.Fragment>
    );
  }
}

export default Audio;