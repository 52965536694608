import React, { Component } from 'react';

import { List } from '../common/Common';
import Play from './Play';
import Pause from './Pause';

import './AudioPanel.css';

class AudioPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
      index: 0
    }
    this.audioRef = React.createRef();
    this.controlEvent = this.controlEvent.bind(this);
    this.pause = this.pause.bind(this);
    this.play = this.play.bind(this);
    this.ended = this.ended.bind(this);
  }

  componentDidMount() { 
    this.audioRef.current.addEventListener('ended', () => {
      this.ended();
    });
  }

  componentDidUpdate() {
    let isPlaying = this.state.isPlaying;
    if (isPlaying === false) {
      this.pause();
    }
    else {
      this.play();
    }
  }

  play() {
    this.audioRef.current.play();
  }

  pause() {
    this.audioRef.current.pause();
  }

  ended() { 
    this.setState({ isPlaying: false });
  }

  controlEvent(i) {
    let newPlayState, newIndex;

    if (this.state.index === i) {
      if (this.state.isPlaying) {
        newPlayState = false;
        newIndex = this.state.index;
      }
      else {
        newPlayState = true;
        newIndex = this.state.index;
      }
    }
    else {
      newPlayState = true;
      newIndex = i;
    }
    this.setState({ isPlaying: newPlayState, index: newIndex });
  }

  render() {
    return (
      <aside className="audio-panel">
        <audio ref={this.audioRef} src={this.props.tracks[this.state.index].audioSrc}></audio>
        <List selectable>
          {this.props.tracks.map((track, i) => {
            return (
              <li key={i} onClick={() => this.controlEvent(i)}>
                <span style={{ marginRight: '.5rem' }}>
                  {
                    this.state.isPlaying && this.state.index === i ?
                      <Pause size="24" /> : <Play size="24" />
                  }
                </span>
                <span>{track.name}</span>
                <span style={{ marginLeft: 'auto' }}>{track.length}</span>
              </li>
            );
          })}
        </List>
      </aside>
    );
  }
}

export default AudioPanel;