import React from 'react';
import { Link } from 'react-router-dom';
import { Page, Section } from '../common/Common';

import './AudioCatalogue.css';
import { audioData } from '../data/AudioData';

const audioCatalogueItems = [
  audioData.flux,
  audioData.endeavour,
  audioData.metropolis,
  audioData.newdawn,
  audioData.floatingpoint,
  audioData.witchinghour
];

const getPrice = (item) => {
  return item.buyUrl === '' ? "Coming Soon" :
         item.price === 0.00 ? "Free" : `$${item.price}`;
}

const AudioCatalogue = () => {
  return (
    <Page>
      <Section className="gray-bg">
          <h1>Catalogue</h1>
          <p style={{ maxWidth: '600px' }}>
            Using an array of acoustic, electric, and computer generated instruments. Hentamine endeavours into theme and melody to produce brilliant works to accompany your game development.
          </p>
          <p>
            Available now on <a href="https://gumroad.com/hentamine">Gumroad</a> and the <a href="https://assetstore.unity.com/publishers/38956">Unity Asset Store.</a>
          </p>
          <div className="audio-catalogue">
          {
            audioCatalogueItems.map((item, i) => {
              return (
                <div key={i} className="audio-catalogue-item">
                  <Link to={`/audio/${item.url}`}>
                    <img src={item.imgSrc} alt={item.altText} />                            
                  </Link>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <h4>{item.title}</h4>
                      <h5>{item.subtitle}</h5>
                    </div>
                    <span className="bold">{getPrice(item)}</span>
                  </div>                 
                </div>
              )
            })
          }
        </div>      
      </Section>
    </Page>
  );
}

export default AudioCatalogue;