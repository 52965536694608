import React from 'react';
import { NavLink } from 'react-router-dom';

const NavLinks = (props) => {
  return (
    <div className="nav-links">
      <NavLink to="/" exact className="nav-link" activeClassName="nav-link-active">       
        Home
      </NavLink>
      <NavLink to="/audio" exact className="nav-link" activeClassName="nav-link-active">
        Audio
      </NavLink>
      <NavLink to="/minecraft" exact className="nav-link" activeClassName="nav-link-active">
        Minecraft
      </NavLink>
      <NavLink to="/contact" exact className="nav-link" activeClassName="nav-link-active">
        Contact
      </NavLink>
    </div>
  );
}

export default NavLinks;