export const audioData = {
  witchinghour: {
    title: 'Witching Hour',
    url: 'witchinghour',
    type: 'Games',
    subtitle: 'Dark',
    imgSrc: 'https://cdn.hentamine.com/audio/artwork/witchinghour.svg',
    imgAlt: 'A creppy green hued face as a moon',
    meta: {
      description: 'Listen to Witching Hour, a collection of dark, ambient, electronic/acoustic compositions for game developers and designers. Available now on the Unity Store.'
    },
    description: [
      `In our debut release, Hentamine explores feelings such as loneliness, sadness, and mystery with haunting melodies and lush harmonies. Witching Hour is a mix of dark, ambient, cinematic electronic/acoustic compositions and loops crafted to bring your game to life and immerse your audience.`,
      `Inspired by the unknown, the supernatural, and the human psyche. Witching Hour is perfect for survival, horror, adventure, sci-fi & RPG genres.`
    ],
    buyUrl: 'https://gum.co/HLYFa',
    altBuyUrl: {
      unity: "https://assetstore.unity.com/packages/audio/music/witching-hour-129190",
      unreal: ""
    },
    time: '80:52 (65:22 + 20:30 loops)',
    format: 'wav',
    release: 'September 26th 2018',
    price: 19.99,
    tracks: [
      {
        name: 'A Silver Lining',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/witchinghour/a-silver-lining.mp3',
        length: '4:23'
      },
      {
        name: 'Blood Moon',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/witchinghour/blood-moon.mp3',
        length: '2:34'
      },
      {
        name: 'Chronos',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/witchinghour/chronos.mp3',
        length: '5:06'
      },
      {
        name: 'Dusk',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/witchinghour/dusk.mp3',
        length: '3:54'
      },
      {
        name: 'Limbo',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/witchinghour/limbo.mp3',
        length: '7:43'
      },
      {
        name: 'Nebula',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/witchinghour/nebula.mp3',
        length: '6:39'
      },
      {
        name: 'New World',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/witchinghour/new-world.mp3',
        length: '5:45'
      },
      {
        name: 'Solitude',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/witchinghour/solitude.mp3',
        length: '7:01'
      },
      {
        name: 'Until Then',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/witchinghour/until-then.mp3',
        length: '8:06'
      },
      {
        name: 'Virdian',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/witchinghour/viridian.mp3',
        length: '8:14'
      },
      {
        name: 'Witching Hour',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/witchinghour/witching-hour.mp3',
        length: '5:48'
      },
    ]
  },
  floatingpoint: {
    title: 'Floating Point',
    url: 'floatingpoint',
    type: 'Games',
    subtitle: 'Atmospheric',
    imgSrc: 'https://cdn.hentamine.com/audio/artwork/floatingpoint.svg',
    imgAlt: 'A woman floating in water relaxing',
    meta: {
      description: 'Listen to Floating Point, a collection of beautiful atmospheric, ambient background compositions for game developers and designers. Available now on the Unity Store.'
    },
    description: [
      `For our sophomore release, Hentamine presents Floating Point; a collection of audio tracks carefully designed to use in the background of your games, for times when you want your game to stand out over the music - the perfect balance between silence and song.`,
      `Floating Point contains ten atmospheric pieces, instilled with beauty and mystery to give a sense of floating and suspension. With each song roughly around five minutes in length, these tracks will enrich your players experience in the more subtle parts of your game.`
    ],
    buyUrl: 'https://gum.co/gvrrig',
    altBuyUrl: {
      unity: "https://assetstore.unity.com/packages/audio/ambient/floating-point-137211",
      unreal: ""
    },    
    time: '52:35',
    format: 'wav',
    release: 'February 22nd 2019',
    price: 9.99,
    tracks: [
      {
        name: 'Alkaline',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/floatingpoint/alkaline.mp3',
        length: '6:36'
      },
      {
        name: 'Circling',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/floatingpoint/circling.mp3',
        length: '3:56'
      },
      {
        name: 'Contemplation',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/floatingpoint/contemplation.mp3',
        length: '5:08'
      },
      {
        name: 'Currents',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/floatingpoint/currents.mp3',
        length: '5:15'
      },
      {
        name: 'Dawn',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/floatingpoint/dawn.mp3',
        length: '5:58'
      },
      {
        name: 'Disengage',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/floatingpoint/disengage.mp3',
        length: '5:14'
      },
      {
        name: 'Floating Point',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/floatingpoint/floating-point.mp3',
        length: '4:56'
      },
      {
        name: 'Forest',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/floatingpoint/forest.mp3',
        length: '4:56'
      },
      {
        name: 'Subterranean',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/floatingpoint/subterranean.mp3',
        length: '5:21'
      },
      {
        name: 'Voyage',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/floatingpoint/voyage.mp3',
        length: '5:15'
      },
    ]
  },
  newdawn: {
    title: 'New Dawn',
    url: 'newdawn',
    type: 'Games',
    subtitle: 'Apocalyptic',
    imgSrc: 'https://cdn.hentamine.com/audio/artwork/newdawn.svg',
    imgAlt: 'Man and dog looking over a bright red sky.',
    meta: {
      description: 'Listen to New Dawn, a collection of apocalyptic compositions for game developers and designers. Available now on the Unity Store.'
    },
    description: [
      `For our third release Hentamine presents 'New Dawn'. A collection of apocalyptic themed tracks that highlight the mindset of an individual caught in an unforgiving new world.`,
      `'New Dawn' consists of multiple pieces composed and recorded on acoustic guitar, with synthesiser and drone backing, to bring you a variety of intricate arrangements that invoke emotions of despair, tragedy but also gleaming moments of hope.`
    ],
    buyUrl: 'https://gum.co/pnBOni',
    altBuyUrl: {
      unity: "https://assetstore.unity.com/packages/audio/music/new-dawn-153816",
      unreal: ""
    },    
    time: '29:58',
    format: 'wav',
    release: 'December 10th 2019',
    price: 9.99,
    tracks: [
      {
        name: 'Prelude',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/prelude.mp3',
        length: '1:27'
      },
      {
        name: 'New Dawn',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/newdawn.mp3',
        length: '2:55'
      },
      {
        name: 'Barren',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/barren.mp3',
        length: '1:30'
      },
      {
        name: 'Aftermath',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/aftermath.mp3',
        length: '3:18'
      },
      {
        name: 'Aftermath (Postlude)',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/aftermath-postlude.mp3',
        length: '0:47'
      },
      {
        name: 'Hunted',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/hunted.mp3',
        length: '2:53'
      },
      {
        name: 'Welcome, Stranger',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/welcome-stranger.mp3',
        length: '2:53'
      },
      {
        name: 'Reflections',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/reflections.mp3',
        length: '1:05'
      },
      {
        name: 'Gunslinger',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/gunslinger.mp3',
        length: '1:58'
      },
      {
        name: 'Homesick',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/homesick.mp3',
        length: '1:34'
      },
      {
        name: 'Badlands',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/badlands.mp3',
        length: '0:56'
      },
      {
        name: 'Moonshine',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/moonshine.mp3',
        length: '2:49'
      },
      {
        name: 'Lonesome Traveller',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/lonesome-traveller.mp3',
        length: '1:13'
      },
      {
        name: 'Campfire',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/campfire.mp3',
        length: '3:00'
      },
      {
        name: 'Memories (Prelude)',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/memories-prelude.mp3',
        length: '0:44'
      },
      {
        name: 'Memories',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/newdawn/memories.mp3',
        length: '3:07'
      },
    ]
  },
  endeavour: {
    title: 'Endeavour',
    url: 'endeavour',
    type: 'Audio',
    subtitle: 'Orchestral',
    imgSrc: 'https://hentaminecdn.blob.core.windows.net/audio/artwork/endeavour.svg',
    imgAlt: 'A conductor orchestrating a symphony.',
    meta: {
      description: 'Listen to Endeavour. A suite of full orchestral arrangements of lush strings, soaring brass and majestic woodwinds.'
    },
    description: [
      'Without further ado, Hentamine presents Endeavour. A suite of full orchestral arrangements of lush strings, soaring brass and majestic woodwinds, held together with tight percussion and coloured with piano and choir.',
      'Endeavour is tailored for game development to not only provide the eight piece suite, but also contain over 100 loops so you can structure these pieces however you choose. We’ve also thrown in some additional loops with different arrangements from the pieces to provide you with as much content as possible.',
      'These ornate orchestral works are ideal for cinematic sequences, highlighting the grandeur of RPG and Adventure games, or providing an interesting backdrop for city building and 4X games.'
    ],
    buyUrl: 'https://gum.co/SzNrx',
    altBuyUrl: {
      unity: "",
      unreal: ""
    }, 
    time: '70:26 (35:03 + 35:23 of loops)',
    format: 'wav',
    release: 'March 30th 2021',
    price: 19.99,
    tracks: [
      {
        name: 'Genesis',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/endeavour/genesis.mp3',
        length: '4:08'
      },
      {
        name: 'Discovery',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/endeavour/discovery.mp3',
        length: '5:08'
      },
      {
        name: 'Lament',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/endeavour/lament.mp3',
        length: '4:39'
      },
      {
        name: 'Conflict',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/endeavour/conflict.mp3',
        length: '5:06'
      },
      {
        name: 'Valour',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/endeavour/valour.mp3',
        length: '3:26'
      },
      {
        name: 'Endeavour',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/endeavour/endeavour.mp3',
        length: '4:55'
      },
      {
        name: 'Civilization',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/endeavour/civilization.mp3',
        length: '4:44'
      },
      {
        name: 'Terminus',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/endeavour/terminus.mp3',
        length: '3:06'
      }
    ]
  },
  flux: {
    title: 'Flux',
    url: 'flux',
    type: 'Audio',
    subtitle: 'Ambient',
    imgSrc: 'https://cdn.hentamine.com/audio/artwork/flux.svg',
    imgAlt: 'A lone pyramid in the middle of an endless desert',
    meta: {
      description: 'Listen to Flux, a collection of ambient audio tracks to bring depth and immersion to your game world.'
    },
    description: [
      'A saturated sunset over the beach, a moon illuminating the endless desert, a vast metropolis glowing further than the eye can see.',
      'Hentamine presents to you, Flux. A collection of ten ambient compositions each meticulously designed and composed with layered synths and drones to immerse and enhance the player experience in your beautifully crafted game environments with rich, sombre, and mysterious themes.'
    ],
    buyUrl: 'https://gum.co/SmwAC',
    altBuyUrl: {
      unity: "",
      unreal: ""
    }, 
    time: '63:13',
    format: 'wav',
    release: 'March 30th 2021',
    price: 9.99,
    tracks: [
      {
        name: 'Cliffside',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/flux/cliffside.mp3',
        length: '6:00'
      },
      {
        name: 'Eventide',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/flux/eventide.mp3',
        length: '7:36'
      },
      {
        name: 'Flux',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/flux/flux.mp3',
        length: '5:44'
      },
      {
        name: 'Presence',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/flux/presence.mp3',
        length: '5:37'
      },
      {
        name: 'Reflection',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/flux/reflection.mp3',
        length: '7:02'
      },
      {
        name: 'Sequential',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/flux/sequential.mp3',
        length: '6:01'
      },
      {
        name: 'Stasis',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/flux/stasis.mp3',
        length: '6:59'
      },
      {
        name: 'Sunrise',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/flux/sunrise.mp3',
        length: '6:25'
      },
      {
        name: 'Twilight',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/flux/twilight.mp3',
        length: '6:18'
      },
      {
        name: 'Vivid',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/flux/vivid.mp3',
        length: '5:31'
      },
    ]
  },
  metropolis: {
    title: 'Metropolis',
    url: 'metropolis',
    type: 'Games',
    subtitle: 'Cyberpunk',
    imgSrc: 'https://cdn.hentamine.com/audio/artwork/metropolis.svg',
    imgAlt: 'A vast city glowing in neon.',
    meta: {
      description: 'Listen to Metroplolis. A blend of four ambient and percussive electronic loops, designed to compliment a variety of game genres and stories in a cyberpunk setting. Free on Unity Asset Store.'
    },
    description: [
      `Hentamine presents Metropolis. A blend of four ambient and percussive electronic loops, designed to compliment a variety of game genres and stories in a cyberpunk setting.`,
      `These four versatile loops explore the classic side of cyberpunk, inspired by classic scores such as Blade Runner and Tron.`
    ],
    buyUrl: 'https://assetstore.unity.com/packages/audio/music/electronic/metropolis-165392',
    time: '4:48',
    format: 'wav',
    release: 'April 1st 2020',
    price: 0.00,
    tracks: [
      {
        name: 'Flow',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/metropolis/flow.mp3',
        length: '1:07'
      },
      {
        name: 'Impulse',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/metropolis/impulse.mp3',
        length: '1:20'
      },
      {
        name: 'Metropolis',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/metropolis/metropolis.mp3',
        length: '1:37'
      },
      {
        name: 'Neon',
        audioSrc: 'https://cdn.hentamine.com/audio/samples/metropolis/neon.mp3',
        length: '0:44'
      }
    ]
  },
  machineonmachine: {
    title: 'Machine On Machine',
    url: 'machineonmachine',
    type: 'Games',
    subtitle: 'Industrial',
    imgSrc: 'https://cdn.hentamine.com/audio/artwork/machineonmachine.svg',
    imgAlt: 'A humanoid robot hooked up to wires',
    meta: {
      description: 'Listen to Machine on Machine, a collection of high octane industrial tracks for games. Available now on the Unity Store.'
    },
    description: [
      `Machine on Machine is a series of high octane, adrenalin pumping and grimy industrial tracks designed to`,
      `Machine on Machine pieces, instilled with beauty and mystery to give a sense of floating and suspension. With each song roughly around five minutes in length, these tracks will enrich your players experience in the more subtle parts of your game.`
    ],
    buyUrl: '',
    time: '38:28',
    format: 'wav',
    release: 'TBA',
    price: 14.99,
    tracks: [      
    ]
  },
}