import React from 'react';
import { Helmet } from 'react-helmet';

import { Page } from '../common/Common';
import AudioPromo from './AudioPromo';
import MinecraftPromo from './MinecraftPromo';


const Home = () => { 
  return (
    <Page>
      <Helmet>
        <title>Hentamine</title>
        <link rel="canonical" href="https://hentamine.com" />
        <meta name="description" content="Just like your mommas cooking, we make our software and audio with love." />
      </Helmet>
      <AudioPromo />
      <MinecraftPromo />        
    </Page>
  );
}

export default Home;