import React from 'react';

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      width="100%" 
      height="100%" 
      viewBox="0 0 453 485"
      className="logo-wrapper">
      <path 
        d="M70.025,17.942c219.579,168.809 -66.373,371.177 -36.617,428.196c2.64,5.063 7.65,-8.633 10.303,-13.695c9.067,-17.368 69.033,-149.885 103.653,-136.576c16.502,6.344 30.495,34.069 39.302,47.492c67.766,103.219 117.523,160.993 240.965,99.83" 
        className="logo"/>
    </svg> 
  );
}
 
export default Logo;