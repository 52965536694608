import React from 'react';
import { Link } from 'react-router-dom';
import { Section } from '../common/Common';

import audioImage from '../assets/hero.svg';

const AudioPromo = () => { 
  return (
    <Section container="content-grid audio-promo" className="lavendar">          
      <div className="content-grid-block">
        <h1>Music For Your Games. Perfected.</h1>
        <p>
          High fidelity audio designed for game development.
        </p>
        <Link to="/audio" className="link-button wide primary">Listen</Link>
      </div>
      <img className="content-grid-media" src={audioImage} alt=""/>          
    </Section>   
  );
}

export default AudioPromo;