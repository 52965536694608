import React from 'react';

import Container from './Container';

const Section = (props) => {
  return (
    <section className={`section ${props.className}`}
        style={{ width: '100%', ...props.style }}
        id={props.id}>
      <Container className={props.container}>
        {props.children}
      </Container>
    </section>
  );
}
 
export default Section;