import React from 'react';
import { Link } from 'react-router-dom';

import Logo from './Logo';
import './Nav.css';

const Nav = (props) => {
  return (
    <nav className="nav" style={props.style}>      
        <div className="nav-brand">
          <Link to="/">
            <Logo />
          </Link>         
        </div>
        {props.children}
    </nav>
  );
}

export default Nav;