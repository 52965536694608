import React from 'react';
import './Container.css';

const Container = (props) => {
  const container = props.className ? `container ${props.className}` : "container";

  return (
    <div className={container}>
      {props.children}
    </div>
  );
}
 
export default Container;